import React, { useContext, useEffect, useState } from 'react';
import { CourseContext } from './contexts/CourseContext';
import { Level, List, Section, Button, Heading, Container, Modal } from 'react-bulma-components';
import { AiOutlineDelete, AiOutlineEdit, AiOutlinePlusCircle, AiOutlineUpload, AiOutlineArrowUp, AiOutlineArrowDown } from 'react-icons/ai';


const AdminCourses = props => {

    const [courses, delCourse, getCourse, setCourses, update, setUpdate] = useContext(CourseContext);
    const [showNewArticle, setShowNewArticle] = useState(false);
    const [title, setTitle] = useState('');
    const [titleValidated, setTitleValidated] = useState(false);
    const [description, setDescription] = useState('');
    const [video, setVideo] = useState('');
    const [videoValidated, setVideoValidated] = useState(false);
    const [codes, setCodes] = useState([]);
    const [documents, setDocuments] = useState([]);
    const [documentsValidated, setDocumentsValidated] = useState([]);
    const [filesForUpload, setFilesForUpload] = useState([]);
    const [docsForUpload, setDocsForUpload] = useState([]);
    const [filesForDeletion, setFilesForDeletion] = useState([]);
    const [docsForDeletion, setDocsForDeletion] = useState([]);
    const [creating, setCreating] = useState(false);
    const [showDeleting, setShowDeleting] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [showEditArticle, setShowEditArticle] = useState(false);
    const [selectedArticleForEdit, setSelectedArticleForEdit] = useState(false);
    const [editing, setEditing] = useState(false);
    const [localUpdate, setLocalUpdate] = useState(false);

    //validation
    useEffect(() => {
        if(title.length > 0) {
            setTitleValidated(true);
        } else {
            setTitleValidated(false);
        }
    },[title])

    useEffect(() => {
        if(video.includes('http:') || video.includes('https:') || video.length === 0) {
            setVideoValidated(true);
        } else {
            setVideoValidated(false);
        }
    },[video])

    useEffect(() => {
        setDocumentsValidated([])
        let temp = [];
        for(let d in docsForUpload) {
            if(docsForUpload[d].name.includes('.pdf')) {
                temp.push(true);
            } else {
                temp.push(false);
            }
        }
        setDocumentsValidated(temp);

    },[docsForUpload])

    const handleCreateArticle = async () => {
        if(titleValidated && videoValidated && documentsValidated.indexOf(false) === -1) {
            setCreating(true);
            if(docsForUpload.length !== 0) {
                await handleUploadDocs(docsForUpload);
            }

            if(filesForUpload.length !== 0) {
                await handleUploadFiles(filesForUpload);
            }

            let docs = []
            for(let doc in docsForUpload) {
                docs.push('https://alcomcoursedocfiles.s3-eu-west-1.amazonaws.com/' + docsForUpload[doc].name)
            }

            let files = []
            for(let file in filesForUpload) {
                files.push('https://alcomcoursecodefiles.s3-eu-west-1.amazonaws.com/' + filesForUpload[file].name)
            }

            let prettifyVideo = video.replaceAll('"',"'");

            fetch('https://q9dyambaka.execute-api.eu-west-1.amazonaws.com/prod/courses/' + props.location.state.course.id + '/articles',{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    title:title,
                    description: description,
                    video: prettifyVideo,
                    codeFiles:files,
                    docFiles:docs,
                    index:props.location.state.course.articles.length
                })
            }).then((res) => res.json())
            .then((res) => {
                setVideo('');
                setTitle('');
                setDescription('');
                setFilesForUpload([]);
                setDocsForUpload([]);
                setFilesForDeletion([]);
                setDocsForDeletion([]);
                setCreating(false);
                setShowNewArticle(false);
                handleCreateArticleLocaly(res.body);
            });

        }  else {
            if(!videoValidated) alert('Feil: Feilformatert URL til video!');
            if(!titleValidated) alert('Feil: En artikkel må han en tittel!');
            return;
        }
    }

    const handleCreateArticleLocaly = (article) => {
        let temp = [];
        for(let a in props.location.state.course.articles) {
            temp.push(props.location.state.course.articles[a]);
        }
        temp.push(article);
        props.location.state.course.articles = [];
        props.location.state.course.articles = [...temp];
        setLocalUpdate(!localUpdate);
    }

    const handleUpdateArticleLocaly = (article) => {
        let temp = [];
        for(let a in props.location.state.course.articles) {
            if(props.location.state.course.articles[a].articleId === article.articleId) {
                temp.push(article);
            } else {
                temp.push(props.location.state.course.articles[a]);
            }
            
        }
        props.location.state.course.articles = [];
        props.location.state.course.articles = [...temp];
        setLocalUpdate(!localUpdate)
    }


    const handleUpdateArticle = async () => {
       
        setEditing(true);
        if(docsForUpload.length !== 0) {
            await handleUploadDocs(docsForUpload);
        }

        if(filesForUpload.length !== 0) {
            await handleUploadFiles(filesForUpload);
        }

        let docs = []
        for(let doc in docsForUpload) {
            docs.push('https://alcomcoursedocfiles.s3-eu-west-1.amazonaws.com/' + docsForUpload[doc].name)
        }
        for(let existingDoc in selectedArticleForEdit.docFiles) {
            if(docsForDeletion.indexOf(selectedArticleForEdit.docFiles[existingDoc]) === -1 ) {
                docs.push(selectedArticleForEdit.docFiles[existingDoc]);
            }
        }

        let files = []
        for(let file in filesForUpload) {
            files.push('https://alcomcoursecodefiles.s3-eu-west-1.amazonaws.com/' + filesForUpload[file].name)
        }
        for(let existingFile in selectedArticleForEdit.codeFiles) {
            if(filesForDeletion.indexOf(selectedArticleForEdit.codeFiles[existingFile]) === -1 ) {
                files.push(selectedArticleForEdit.codeFiles[existingFile]);
            }
        }

        let prettifyVideo = video.replaceAll('"',"'");

        fetch('https://q9dyambaka.execute-api.eu-west-1.amazonaws.com/prod/courses/' + props.location.state.course.id + '/articles/' + selectedArticleForEdit.articleId + '/edit',{
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                articleId:selectedArticleForEdit.articleId,
                title:title.length === 0 ? selectedArticleForEdit.title : title,
                description:description.length === 0 ? selectedArticleForEdit.description : description,
                video: prettifyVideo.length === 0 ? selectedArticleForEdit.video : video,
                codeFiles:files,
                docFiles:docs,
                index:selectedArticleForEdit.index
            })
        }).then((res) => res.json())
        .then((res) => {
            setVideo('');
            setTitle('');
            setDescription('');
            setFilesForUpload([]);
            setDocsForUpload([]);
            setFilesForDeletion([]);
            setDocsForDeletion([]);
            setEditing(false);
            setShowEditArticle(false);
            handleUpdateArticleLocaly(res.body)
        });
        
    }

    const handleUploadDocs = async (docs) => {
        return new Promise(async resolve => {
            for(let i = 0; i < docs.length; i++) {
                let signedUrl = await handleUploadDocSignature(docs[i]);
                await handleUploadDoc(signedUrl,docs[i]);
            }
            resolve();
        });
        
    }

    const handleUploadDocSignature = async (doc) => {
        return new Promise(resolve => {
            fetch('https://q9dyambaka.execute-api.eu-west-1.amazonaws.com/prod/documents/signed', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    filename: doc.name
                })
            }).then((res) => res.json())
                .then((res) => {
                    resolve(res.body);
                })
        });
    }

    const handleUploadDoc = async (signedUrl, file) => {
        
        return new Promise(resolve => {
           
            fetch(signedUrl,{
                method: 'PUT',
                headers: {
                    'Content-Type': file.type
                },
                body: file
            }).then((res) => {
                resolve(res)
            })
        });
    }

    const handleUploadFiles = async (files) => {
        return new Promise(async resolve => {        
            for(let i = 0; i < files.length; i++) {
                let signedUrl = await handleUploadFileSignature(files[i]);       
                await handleUploadFile(signedUrl,files[i]);
            }
            resolve();
        });
    }

    const handleUploadFileSignature = async (file) => {
        return new Promise(resolve => {
            fetch('https://q9dyambaka.execute-api.eu-west-1.amazonaws.com/prod/files/signed', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    filename: file.name
                })
            }).then((res) => res.json())
                .then((res) => {
                    resolve(res.body);
                })
        });
    }

    const handleUploadFile = async (signedUrl, file) => {
        
        return new Promise(resolve => {
            fetch(signedUrl,{
                method: 'PUT',
                headers: {
                    'Content-Type': 'binary/octet-stream'
                },
                body: file
            }).then((res) => {
                resolve(res)
            })
        });
    }

    const handleDeleteArticle = (id) => {
        setDeleting(true);
        fetch('https://q9dyambaka.execute-api.eu-west-1.amazonaws.com/prod/courses/' + props.location.state.course.id + '/articles/' + id, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
        .then(res => {
            setDeleting(false);
            setShowDeleting(false);
            handleDeleteArticleLocaly(id);
        });
    }

    const handleDeleteArticleLocaly = (id) => {
        let temp = [];
        for(let a in props.location.state.course.articles) {
            if(props.location.state.course.articles[a].articleId !== id) {
                temp.push(props.location.state.course.articles[a])
            }
        }
        props.location.state.course.articles = [];
        props.location.state.course.articles = [...temp];
        setLocalUpdate(!localUpdate)
    }

    const handleMoveCourseUp = async (article) => {
        
        let articleCopy = { ...article };
        articleCopy.index--;

        let affectedArticle = null;

        for(let i = 0; i < props.location.state.course.articles.length; i++) {
            if(props.location.state.course.articles[i].index === articleCopy.index) {
               
                affectedArticle = { ...props.location.state.course.articles[i] };
            }
        }

        if(affectedArticle === null) {
            affectedArticle = { index: 'NaN' };
        }

        affectedArticle.index++;

        await fetch('https://q9dyambaka.execute-api.eu-west-1.amazonaws.com/prod/courses/' + props.location.state.course.id + '/articles/' + articleCopy.articleId + '/edit/index',{
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                index: articleCopy.index
            })
        })

        await fetch('https://q9dyambaka.execute-api.eu-west-1.amazonaws.com/prod/courses/' + props.location.state.course.id + '/articles/' + affectedArticle.articleId + '/edit/index',{
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                index: affectedArticle.index
            })
        })
        

        const updateArticles = async () => {
            return new Promise((resolve) => {
                resolve(setUpdate(!update));
            })
        } 
        
        await updateArticles();

        let temp = []
        for(let i = 0; i < props.location.state.course.articles.length; i++) {
            if(props.location.state.course.articles[i].articleId === articleCopy.articleId) {
                temp.push(articleCopy);    
            } else if(props.location.state.course.articles[i].articleId === affectedArticle.articleId) {
                temp.push(affectedArticle);
            } else {
                temp.push(props.location.state.course.articles[i]);
            }
        }
        temp.sort((a,b) => {
            if(a.index > b.index) return 1;
            if(a.index < b.index) return -1;
            return 0
        })

        props.location.state.course.articles = []
        props.location.state.course.articles = [...temp]

        setLocalUpdate(!localUpdate);

    }

    const handleMoveCourseDown = async (article) => {
        
        let articleCopy = { ...article };
        articleCopy.index++;

        let affectedArticle = null;

        for(let i = 0; i < props.location.state.course.articles.length; i++) {
            if(props.location.state.course.articles[i].index === articleCopy.index) {       
                affectedArticle = { ...props.location.state.course.articles[i] };
            }
        }

        if(affectedArticle === null) {
            affectedArticle = { index: 'NaN' };
        }

        affectedArticle.index--;


        await fetch('https://q9dyambaka.execute-api.eu-west-1.amazonaws.com/prod/courses/' + props.location.state.course.id + '/articles/' + articleCopy.articleId + '/edit/index',{
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                index: articleCopy.index
            })
        })

        await fetch('https://q9dyambaka.execute-api.eu-west-1.amazonaws.com/prod/courses/' + props.location.state.course.id + '/articles/' + affectedArticle.articleId + '/edit/index',{
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                index: affectedArticle.index
            })
        })
        

        const updateArticles = async () => {
            return new Promise((resolve) => {
                resolve(setUpdate(!update));
            })
        } 
        
        await updateArticles();

        let temp = []
        for(let i = 0; i < props.location.state.course.articles.length; i++) {
            if(props.location.state.course.articles[i].articleId === articleCopy.articleId) {
                temp.push(articleCopy);    
            } else if(props.location.state.course.articles[i].articleId === affectedArticle.articleId) {
                temp.push(affectedArticle);
            } else {
                temp.push(props.location.state.course.articles[i]);
            }
        }
        temp.sort((a,b) => {
            if(a.index > b.index) return 1;
            if(a.index < b.index) return -1;
            return 0
        })

        props.location.state.course.articles = []
        props.location.state.course.articles = [...temp]

        setLocalUpdate(!localUpdate);
    }

    return (
        <Section>
            <Container>
                <Section>
                    <Heading>{props.location.state.course.name}</Heading>
                    <Container>
                        <Heading size={4}>Artikler</Heading>
                        <Section>
                            <Container>
                                <List>
                                    {
                                        props.location.state.course.articles.map((article, key) => {
                                            return (
                                                <List.Item key={key}>
                                                    <Level>
                                                        <Level.Side align='left'>
                                                            <Level.Item>
                                                                {(key + 1) + '. ' + article.title}
                                                            </Level.Item>
                                                        </Level.Side>
                                                        <Level.Side align='right'>
                                                            <Level.Item 
                                                                style={{ cursor: 'pointer' }}
                                                                onClick={() => setShowDeleting(true)}
                                                            >
                                                                <AiOutlineDelete size={20} />
                                                            </Level.Item>
                                                            <Level.Item 
                                                                style={{ cursor: 'pointer' }}
                                                                onClick={() => {
                                                                    setSelectedArticleForEdit(article);
                                                                    setShowEditArticle(true);
                                                                }}
                                                            >
                                                                <AiOutlineEdit size={20} />
                                                            </Level.Item>
                                                            {
                                                                key !== 0 ? 
                                                                <Level.Item style={{ cursor: 'pointer' }}>
                                                                    
                                                                    <AiOutlineArrowUp 
                                                                        size='1.5em'
                                                                        onClick={() => handleMoveCourseUp(article)}
                                                                    />
                                                                    
                                                                    
                                                                </Level.Item>
                                                                : null
                                                            }
                                                            {
                                                                key !== props.location.state.course.articles.length-1 ? 
                                                                <Level.Item style={{ cursor: 'pointer' }}>
                                                                    
                                                                    <AiOutlineArrowDown 
                                                                        size='1.5em'
                                                                        onClick={() => handleMoveCourseDown(article)}
                                                                    />
                                                                    
                                                                    
                                                                </Level.Item>
                                                                : null
                                                            }
                                                        </Level.Side>
                                                    </Level>
                                                    <Modal
                                                        show={showDeleting}
                                                        onClose={() => setShowDeleting(false)}
                                                    >
                                                        <Modal.Content>
                                                            <Section style={{ backgroundColor: 'white' }} >
                                                                <Section>
                                                                    Vil du slette artikelen {article.title}?
                                                                </Section>
                                                                <Section>
                                                                    <Level>
                                                                        <Level.Side align='left'>
                                                                            <Level.Item>
                                                                                <Button
                                                                                    color='success'
                                                                                    loading={deleting}
                                                                                    onClick={() => { handleDeleteArticle(article.articleId) }}
                                                                                >
                                                                                    OK
                                                                            </Button>
                                                                            </Level.Item>
                                                                            <Level.Item>
                                                                                <Button
                                                                                    color='danger'
                                                                                    onClick={() => setShowDeleting(false)}
                                                                                >
                                                                                    Avbryt
                                                                                </Button>
                                                                            </Level.Item>
                                                                        </Level.Side>
                                                                    </Level>
                                                                </Section>
                                                            </Section>
                                                        </Modal.Content>
                                                    </Modal>
                                                    {
                                                        showEditArticle && selectedArticleForEdit.articleId === article.articleId?
                                                            <Section>
                                                                <Heading size={5}>Oppdater artikkel</Heading>
                                                                <Container>
                                                                    <div className='field'>
                                                                        <div className='label' >Tittel</div>
                                                                        <div className='control'>
                                                                            <input className={titleValidated ? 'input is-success' : 'input is-danger' } type='text' defaultValue={article.title} onChange={(e) => { setTitle(e.target.value) }} />
                                                                        </div>
                                                                    </div>
                                                                    <div className='field'>
                                                                        <div className='label' >Beskrivelse</div>
                                                                        <div className='control'>
                                                                            <textarea className={'textarea'} type='text' defaultValue={article.description} onChange={(e) => { setDescription(e.target.value) }} />
                                                                        </div>
                                                                    </div>
                                                                    <div className='field'>
                                                                        <div className='label' >Videokilde (URL)</div>
                                                                        <div className='control'>
                                                                            <input className={videoValidated ? 'input is-success' : 'input is-danger'} defaultValue={article.video} onChange={(e) => { setVideo(e.target.value) }} />
                                                                        </div>
                                                                    </div>

                                                                    <div className='field'>
                                                                        <div className='label' >Kodefiler</div>
                                                                            <div className="file has-name is-fullwidth">
                                                                                <label className="file-label">
                                                                                    <input 
                                                                                        className="file-input" 
                                                                                        type="file"
                                                                                        name="resume" 
                                                                                        onChange={(e) => {setFilesForUpload([...filesForUpload, e.target.files[0]])} 
                                                                                    }/>
                                                                                        <span className="file-cta">
                                                                                            <span className="file-icon">
                                                                                                <AiOutlineUpload />
                                                                                            </span>
                                                                                            <span className="file-label">
                                                                                                Velg en fil…
                                                                                            </span>
                                                                                        </span>
                                                                                </label>                    
                                                                            </div>
                                                                    </div>
                                                                    {
                                                                        article.codeFiles.length > 0  || filesForUpload.length > 0?
                                                                            <Section>
                                                                                <Heading size={6} >Kodefiler for opplasting</Heading>
                                                                                    <Container>
                                                                                        <List>
                                                                                        {
                                                                                            article.codeFiles.map((file, key) => {
                                                                                                return (
                                                                                                    <List.Item key={key}> 
                                                                                                        <Level>
                                                                                                            <Level.Side align='left'>
                                                                                                                <Level.Item>
                                                                                                                    {file.replace('https://alcomcoursecodefiles.s3-eu-west-1.amazonaws.com/','')}
                                                                                                                </Level.Item>
                                                                                                            </Level.Side>
                                                                                                            <Level.Side align='right'>
                                                                                                                <Level.Item 
                                                                                                                    style={{ cursor:'pointer' }}
                                                                                                                    onClick={ () => {
                                                                                                                        setFilesForDeletion([...filesForDeletion,file])
                                                                                                                        let temp = []
                                                                                                                        for(let d in article.codeFiles) {
                                                                                                                            if(article.codeFiles[d] !== file) {
                                                                                                                                temp.push(article.codeFiles[d])
                                                                                                                            }
                                                                                                                        }
                                                                                                                        article.codeFiles=[];
                                                                                                                        article.codeFiles=[...temp];
                                                                                                                        setLocalUpdate(!localUpdate);
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <AiOutlineDelete size={20} />
                                                                                                                </Level.Item>
                                                                                                            </Level.Side>
                                                                                                        </Level>
                                                                                                    </List.Item>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                        {
                                                                                            filesForUpload.map((file, key) => {
                                                                                                return (
                                                                                                    <List.Item key={key}> 
                                                                                                        <Level>
                                                                                                            <Level.Side align='left'>
                                                                                                                <Level.Item>
                                                                                                                    {file.name}
                                                                                                                </Level.Item>
                                                                                                            </Level.Side>
                                                                                                            <Level.Side align='right'>
                                                                                                                <Level.Item 
                                                                                                                    style={{ cursor:'pointer' }}
                                                                                                                    onClick={ () => setFilesForUpload(filesForUpload.filter( item => item !== file )) }
                                                                                                                >
                                                                                                                    <AiOutlineDelete size={20} />
                                                                                                                </Level.Item>
                                                                                                            </Level.Side>
                                                                                                        </Level>
                                                                                                    </List.Item>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </List>
                                                                                </Container>
                                                                            </Section>
                                                                    : null
                                                                    }
                                                                    <div className='field'>
                                                                        <div className='label' >Dokumenter</div>
                                                                        <div className="file has-name is-fullwidth">
                                                                            <label className="file-label">
                                                                                <input className="file-input" type="file" name="resume" onChange={(e) => setDocsForUpload([...docsForUpload, e.target.files[0]])} />
                                                                                    <span className="file-cta">
                                                                                        <span className="file-icon">
                                                                                            <AiOutlineUpload />
                                                                                        </span>
                                                                                        <span className="file-label">
                                                                                            Velg en fil…
                                                                                        </span>
                                                                                    </span>
                                                                            </label>                    
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        article.docFiles.length > 0  || docsForUpload.length > 0 ?
                                                                            <Section>
                                                                                <Heading size={6} >Dokumenter for opplasting</Heading>
                                                                                    <Container>
                                                                                        <List>
                                                                                        {
                                                                                            article.docFiles.map((file, key) => {
                                                                                                return (
                                                                                                    <List.Item key={key}> 
                                                                                                        <Level>
                                                                                                            <Level.Side align='left'>
                                                                                                                <Level.Item>
                                                                                                                    {file.replace('https://alcomcoursedocfiles.s3-eu-west-1.amazonaws.com/','')}
                                                                                                                </Level.Item>
                                                                                                            </Level.Side>
                                                                                                            <Level.Side align='right'>
                                                                                                                <Level.Item 
                                                                                                                    style={{ cursor:'pointer' }}
                                                                                                                    onClick={ () => {
                                                                                                                        setDocsForDeletion([...docsForDeletion,file])
                                                                                                                        let temp = []
                                                                                                                        for(let d in article.docFiles) {
                                                                                                                            if(article.docFiles[d] !== file) {
                                                                                                                                temp.push(article.docFiles[d])
                                                                                                                            }
                                                                                                                        }
                                                                                                                        article.docFiles=[];
                                                                                                                        article.docFiles=[...temp];
                                                                                                                        setLocalUpdate(!localUpdate);
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <AiOutlineDelete size={20} />
                                                                                                                </Level.Item>
                                                                                                            </Level.Side>
                                                                                                        </Level>
                                                                                                    </List.Item>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                        {
                                                                                            docsForUpload.map((file, key) => {
                                                                                                return (
                                                                                                    <List.Item key={key}> 
                                                                                                        <Level>
                                                                                                            <Level.Side align='left'>
                                                                                                                <Level.Item style={{color: documentsValidated[key] ? 'unset' : 'red'}}>
                                                                                                                    {file.name}
                                                                                                                </Level.Item>
                                                                                                            </Level.Side>
                                                                                                            <Level.Side align='right'>
                                                                                                                <Level.Item 
                                                                                                                    style={{ cursor:'pointer' }}
                                                                                                                    onClick={ () => setDocsForUpload(docsForUpload.filter( item => item !== file))}
                                                                                                                >
                                                                                                                    <AiOutlineDelete size={20} />
                                                                                                                </Level.Item>
                                                                                                            </Level.Side>
                                                                                                        </Level>
                                                                                                    </List.Item>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </List>
                                                                                </Container>
                                                                            </Section>
                                                                    : null
                                                                    }
                                                                    <Section>
                                                                        <Container>
                                                                            <Button
                                                                                color='success'
                                                                                loading={editing}
                                                                                onClick={() => handleUpdateArticle()}
                                                                            >
                                                                                Lagre
                                                                            </Button>
                                                                            <Button
                                                                                color='danger'
                                                                                onClick = {() => setShowEditArticle(false)}
                                                                                style={{marginLeft:'1em'}}
                                                                            >
                                                                                Avbryt
                                                                            </Button>
                                                                        </Container>
                                                                    </Section>
                                                            </Container>
                                                            </Section>
                                                            
                                                        : null
                                                    }
                                                </List.Item>
                                            )
                                        })
                                    }
                                    {
                                        showNewArticle ?
                                            <List.Item>
                                                <Section>
                                                    <Heading size={5}>Ny artikkel</Heading>
                                                    <Container>
                                                        <div className='field'>
                                                            <div className='label' >Tittel</div>
                                                            <div className='control'>
                                                                <input className={titleValidated ? 'input is-success' : 'input is-danger' } type='text' onChange={(e) => { setTitle(e.target.value) }} />
                                                            </div>
                                                        </div>
                                                        <div className='field'>
                                                            <div className='label' >Beskrivelse</div>
                                                                <div className='control'>
                                                                    <textarea className={'textarea'} type='text' onChange={(e) => { setDescription(e.target.value) }} />
                                                                </div>
                                                            </div>
                                                        <div className='field'>
                                                            <div className='label' >Videokilde (URL)</div>
                                                            <div className='control'>
                                                                <input className={videoValidated ? 'input is-success' : 'input is-danger'} onChange={(e) => { setVideo(e.target.value) }} />
                                                            </div>
                                                        </div>

                                                        <div className='field'>
                                                            <div className='label' >Kodefiler</div>
                                                                <div className="file has-name is-fullwidth">
                                                                    <label className="file-label">
                                                                        <input 
                                                                            className="file-input" 
                                                                            type="file"
                                                                            name="resume" 
                                                                            onChange={(e) => {
                                                                                setFilesForUpload([...filesForUpload, e.target.files[0]])
                                                                            } 
                                                                        }/>
                                                                            <span className="file-cta">
                                                                                <span className="file-icon">
                                                                                    <AiOutlineUpload />
                                                                                </span>
                                                                                <span className="file-label">
                                                                                    Velg en fil…
                                                                                </span>
                                                                            </span>
                                                                    </label>                    
                                                                </div>
                                                        </div>
                                                        {
                                                            filesForUpload.length > 0 ?
                                                                <Section>
                                                                    <Heading size={6} >Kodefiler for opplasting</Heading>
                                                                        <Container>
                                                                            <List>
                                                                            {
                                                                                filesForUpload.map((file, key) => {
                                                                                    return (
                                                                                        <List.Item key={key}> 
                                                                                            <Level>
                                                                                                <Level.Side align='left'>
                                                                                                    <Level.Item>
                                                                                                        {file.name}
                                                                                                    </Level.Item>
                                                                                                </Level.Side>
                                                                                                <Level.Side align='right'>
                                                                                                    <Level.Item 
                                                                                                        style={{ cursor:'pointer' }}
                                                                                                        onClick={ () => setFilesForUpload(filesForUpload.filter( item => item !== file )) }
                                                                                                    >
                                                                                                        <AiOutlineDelete size={20} />
                                                                                                    </Level.Item>
                                                                                                </Level.Side>
                                                                                            </Level>
                                                                                        </List.Item>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </List>
                                                                    </Container>
                                                                </Section>
                                                           : null
                                                        }
                                                        <div className='field'>
                                                            <div className='label' >Dokumenter</div>
                                                            <div className="file has-name is-fullwidth">
                                                                <label className="file-label">
                                                                    <input className="file-input" type="file" name="resume" onChange={(e) => setDocsForUpload([...docsForUpload, e.target.files[0]])} />
                                                                        <span className="file-cta">
                                                                            <span className="file-icon">
                                                                                <AiOutlineUpload />
                                                                            </span>
                                                                            <span className="file-label">
                                                                                Velg en fil…
                                                                            </span>
                                                                        </span>
                                                                </label>                    
                                                            </div>
                                                        </div>
                                                        {
                                                            docsForUpload.length > 0 ?
                                                                <Section>
                                                                    <Heading size={6} >Dokumenter for opplasting</Heading>
                                                                        <Container>
                                                                            <List>
                                                                            {
                                                                                docsForUpload.map((file, key) => {
                                                                                    return (
                                                                                        <List.Item key={key}> 
                                                                                            <Level>
                                                                                                <Level.Side align='left'>
                                                                                                    <Level.Item style={{color: documentsValidated[key] ? 'unset' : 'red'}}>
                                                                                                        {file.name}
                                                                                                    </Level.Item>
                                                                                                </Level.Side>
                                                                                                <Level.Side align='right'>
                                                                                                    <Level.Item 
                                                                                                        style={{ cursor:'pointer' }}
                                                                                                        onClick={ () => setDocsForUpload(docsForUpload.filter( item => item !== file )) }
                                                                                                    >
                                                                                                        <AiOutlineDelete size={20} />
                                                                                                    </Level.Item>
                                                                                                </Level.Side>
                                                                                            </Level>
                                                                                        </List.Item>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </List>
                                                                    </Container>
                                                                </Section>
                                                           : null
                                                        }
                                                        <Section>
                                                            <Container>
                                                                <Button
                                                                    color='success'
                                                                    loading={creating}
                                                                    onClick={() => handleCreateArticle()}
                                                                >
                                                                    Lagre
                                                                </Button>
                                                                <Button
                                                                    color='danger'
                                                                    onClick = {() => setShowNewArticle(false)}
                                                                    style={{marginLeft:'1em'}}
                                                                >
                                                                    Avbryt
                                                                </Button>
                                                            </Container>
                                                        </Section>
                                                   </Container>
                                                </Section>
                                            </List.Item>
                                        : null
                                    }
                                    <List.Item>
                                        <Level>
                                            <Level.Item style={{ cursor: 'pointer' }}>
                                                <AiOutlinePlusCircle size={30} onClick={() => setShowNewArticle(true)} />
                                            </Level.Item>
                                        </Level>
                                    </List.Item>     
                                </List>
                            </Container>
                        </Section>
                    </Container>
                </Section>
            </Container>
        </Section>
    );

}

export default AdminCourses;
