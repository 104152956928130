import React, { useState, useEffect, useContext } from 'react';
import { Section, Button, Container, Heading} from 'react-bulma-components';
import { useHistory } from 'react-router-dom';
import { StatusContext } from './contexts/StatusContext';
import {Auth} from 'aws-amplify';

const LoginForgot = (props) => {
    const [email, setEmail] = useState('');
    const [requested, setRequested] = useState(false);
    const [validEmail, setValidEmail] = useState(false);
    const [code, setCode] = useState('');
    const [validCode, setValidCode] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [validPasswords, setValidPasswords] = useState(false);
    const history = useHistory();

    useEffect(() => {
        Auth.currentAuthenticatedUser()
        .then((user) => {
            history.push('/');
        })
    },[]);


    useEffect(() => {
        if(email.includes('@') && email.includes('.')) {
            setValidEmail(true);
        } else {
            setValidEmail(false);
        }
    },[email]);

    useEffect(() => {
        if(password.length > 7 && confirmPassword.length > 7 && password === confirmPassword) {
            setValidPasswords(true);
        } else {
            setValidPasswords(false);
        }
    },[password, confirmPassword]);

    useEffect(() => {
        if(code.length > 5 ) {
            setValidCode(true);
        } else {
            setValidCode(false);
        }
    },[code]);


    const handleRequestPasswordChange = () => {
        if(validEmail) {
            Auth.forgotPassword(email.trim().toLowerCase())
            .then(res => {
                setRequested(true);
                
            }).catch((e) => {
                console.log(e);
                if(e.code === "UserNotFoundException") {
                    alert('FEIL: Eposten finnes ikke!')
                }
            });
        } else {
            alert('FEIL: Epost mangler!');
        }
    }

    const handleVerifyPasswordChange = () => {
        if(validEmail && validCode && validPasswords) {
            Auth.forgotPasswordSubmit(email.trim().toLowerCase(),code.trim(),password.trim())    
            .then((res) => {
                setRequested(false);
                history.goBack();
            })
            .catch((e) => {
                if(e.code === "CodeMismatchException") {
                    alert('FEIL: Verifiseringskoden er ikke korrekt!')
                }
            })
        } else {
            if(!validEmail) alert('FEIL: Epost mangler!')
            else if (!validCode) alert('FEIL: Verifisieringskode mangler!')
            else if (!validPasswords) { password.length < 8 ? alert('FEIL: Passord for kort!') : alert('FEIL: Passord og Bekreft passord er ikke like!')}
        }
        
    }

    return (
        <Section >
            <Container style={{maxWidth:'40%', marginTop:'2em'}}>
                <Heading size={4}>Glemt passord</Heading> 
            </Container>
            <Container style={{maxWidth:'40%', marginTop:'2em'}}>
                <div className='field'>
                    <div className='label'>Epost</div>
                    <div className='control'>
                        <input type='text' className={validEmail ? 'input is-success': 'input is-danger'} onChange={(e) => { setEmail(e.target.value) }} />
                    </div>
                </div>
                {
                    requested ? 
                        <div className='field'>
                            <div className='label'>Verifiseringskode (sendt på epost)</div>
                            <div className='control'>
                                <input type='password' className={validCode? 'input is-success': 'input is-danger'}  onChange={(e) => { setCode(e.target.value) }} />
                            </div>
                        </div>
                    : null
                }
                {
                    requested ? 
                        <div className='field'>
                            <div className='label'>Nytt passord</div>
                            <div className='control'>
                                <input type='password' className={validPasswords ? 'input is-success': 'input is-danger'} onChange={(e) => { setPassword(e.target.value) }} />
                            </div>
                        </div>
                    : null
                }
                {
                    requested ? 
                        <div className='field'>
                            <div className='label'>Bekreft nytt passord</div>
                            <div className='control'>
                                <input type='password' className={validPasswords ? 'input is-success': 'input is-danger'} onChange={(e) => { setConfirmPassword(e.target.value) }} />
                            </div>
                        </div>
                    : null
                }
                {
                    requested ? 
                    <Button 
                        className="is-info"
                        onClick={() => handleVerifyPasswordChange()}
                    >
                        Bekreft
                    </Button>
                    : 
                    <Button 
                        className="is-info"
                        onClick={() => handleRequestPasswordChange()}
                    >
                        Hent
                    </Button>
                }        
            </Container>
        </Section>
    );
}

export default LoginForgot;
