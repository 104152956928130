import React, { useState, useEffect, useContext } from 'react';
import { Section, Button, Container, Heading} from 'react-bulma-components';
import { useHistory } from 'react-router-dom';
import { StatusContext } from './contexts/StatusContext';
import {Auth} from 'aws-amplify';

const Confirm = (props) => {
    const [email, setEmail] = useState('');
    const [validEmail, setValidEmail] = useState(false);
    const [tempPassword, setTempPassword] = useState('');
    const [validTempPassword, setValidTempPassword] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [validPasswords, setValidPasswords] = useState(false);
    const history = useHistory();
    const [loggedIn, setLoggedIn, isAdmin, setIsAdmin, updateHeader, setUpdateHeader] = useContext(StatusContext)


    useEffect(() => {
        Auth.currentAuthenticatedUser()
        .then((user) => {
            history.push('/');
        })
    },[]);


    useEffect(() => {
        if(email.includes('@') && email.includes('.')) {
            setValidEmail(true);
        } else {
            setValidEmail(false);
        }
    },[email]);


    useEffect(() => {
        if(password.length > 7 && confirmPassword.length > 7 && password === confirmPassword) {
            setValidPasswords(true);
        } else {
            setValidPasswords(false);
        }
    },[password, confirmPassword]);

    useEffect(() => {
        if(tempPassword.length > 7 ) {
            setValidTempPassword(true);
        } else {
            setValidTempPassword(false);
        }
    },[tempPassword]);

    const handleCreateNewPassword = async () => {
        if(validEmail && validPasswords && validTempPassword) {
            Auth.signIn(email.toLowerCase(),tempPassword.trim())
            .then(res => {
                if(res.challengeName === "NEW_PASSWORD_REQUIRED") {
                    Auth.completeNewPassword(res,password.trim())
                    .then((res) => {
                        setUpdateHeader(!updateHeader);
                        history.push('/');
                    })
                    .catch((e) => {
                        console.log('NewPasswordError: ');
                        console.log(e);
                    })
                }
            }).catch((e) => {
                console.log('TempLoginError: ');
                console.log(e);
                if(e.code === "NotAuthorizedException") {
                    alert('Epost eller midlertidig passord er feil!')
                }
            });
        } else {
            if(!validTempPassword) {
                alert('Feil: Du må fylle inn det midlertidig passordet du har mottatt på epost!');
            } else if(!validPasswords) {
                alert('Feil: Passordene må minst bestå av 8 tegn og passord og bekreftpassord må være identiske!');
            }else if(!validEmail) {
                alert('Feil: Epost er feil formatert');
            }
        }
    }

    return (
        <Section >
            <Container style={{maxWidth:'40%', marginTop:'2em'}}>
                <Heading size={4}>Lag nytt passord</Heading> 
            </Container>
            <Container style={{maxWidth:'40%', marginTop:'2em'}}>
                <div className='field'>
                    <div className='label'>Epost</div>
                    <div className='control'>
                        <input type='text' className={validEmail ? 'input is-success': 'input is-danger'} onChange={(e) => { setEmail(e.target.value) }} />
                    </div>
                </div>
                <div className='field'>
                    <div className='label'>Midlertidig passord (sendt på epost)</div>
                    <div className='control'>
                        <input type='password' className={validTempPassword ? 'input is-success': 'input is-danger'}  onChange={(e) => { setTempPassword(e.target.value) }} />
                    </div>
                </div>
                <div className='field'>
                    <div className='label'>Nytt passord</div>
                    <div className='control'>
                        <input type='password' className={validPasswords ? 'input is-success': 'input is-danger'} onChange={(e) => { setPassword(e.target.value) }} />
                    </div>
                </div>
                <div className='field'>
                    <div className='label'>Bekreft nytt passord</div>
                    <div className='control'>
                        <input type='password' className={validPasswords ? 'input is-success': 'input is-danger'} onChange={(e) => { setConfirmPassword(e.target.value) }} />
                    </div>
                </div>
                <Button 
                    className="is-info"
                    onClick={() => handleCreateNewPassword()}
                >
                    Lag nytt passord
                </Button>
            </Container>
        </Section>
    );
}

export default Confirm;
