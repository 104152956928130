
import React, { useEffect, useState } from 'react';
import {Auth} from "aws-amplify";
import { Section, Container, Heading, Columns} from 'react-bulma-components';
import { useHistory } from 'react-router-dom';
import front1 from './assets/images/front_1.jpg';
import front2 from './assets/images/front_2.png';

const Home = () => {

    const history = useHistory();

    useEffect(() => {
        Auth.currentAuthenticatedUser()
        .then((user) => {
            
        })
        .catch((e) => {
            history.push('/login');
        });
    },[])


    return (
        
            <Columns className="is-centered">
                <Columns.Column className='is-8'>
                    <Section>
                        <Container>
                        <Section>
                            <Container style={{textAlign:'center'}} >
                                <Heading> Velkommen til Alcom kurskatalog</Heading>
                                <Container>
                                    Her finner du ressurser og kurs for våre produkter.
                                </Container>
                            </Container>
                        </Section>
                        <Section>
                            <Container style={{textAlign:'center'}}>
                                <img src={front1} alt='front 1' width='400px' />
                                <img src={front2} alt='front 2' width='400px'/>
                            </Container>
                        </Section>
                        </Container>
                    </Section>
                </Columns.Column>
            </Columns>
        
    );
}

export default Home;
