import './App.css';
import 'react-bulma-components/dist/react-bulma-components.min.css';
import Header from './Header';
import Course from './Course';
import Admin from './Admin';
import Login from './Login';
import LoginForgot from './LoginForgot';
import Confirm from './Confirm';
import Home from './Home';
import AdminCourses from './AdminCourses';
import AdminUsers from  './AdminUsers';
import {StatusContextProvider} from './contexts/StatusContext';
import {CourseContextProvider} from './contexts/CourseContext';
import AdminCourse from './AdminCourse';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import Amplify from "aws-amplify";
import awsExports from "./aws-exports";
Amplify.configure(awsExports);

const App = () => {
  return (
    <div className="app">
      <Router>
        <StatusContextProvider>
          <CourseContextProvider>
            <Header></Header>
              <Switch>
                <Route exact path="/login" component={Login} />
                <Route exact path="/login/forgot" component={LoginForgot} />
                <Route exact path="/confirm" component={Confirm} />
                <Route exact path="/courses/:courseId" component={Course} />
                <Route exact path="/admin/courses" component={AdminCourses} />
                <Route exact path="/admin/course/:courseId" component={AdminCourse} />
                <Route exact path="/admin/users" component={AdminUsers} />
                <Route exact path="/" component={Home} />
              </Switch>
          </CourseContextProvider>
        </StatusContextProvider>
      </Router>
    </div>
  );
}

export default App;
