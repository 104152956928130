import React, { useContext, useEffect, useState } from 'react';
import logo from './alcom_logo.png';
import { Navbar } from 'react-bulma-components';
import { StatusContext } from './contexts/StatusContext'
import { CourseContext } from './contexts/CourseContext'
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';

const Header = props => {
    const [loggedIn, setLoggedIn, isAdmin, setIsAdmin] = useContext(StatusContext);
    const [courses, delCourse, getCourse] = useContext(CourseContext);
    const [activeBurger, setActiveBurger] = useState(false);
    const history = useHistory();

    return (
        <div>
            <Navbar fixed='top' className='headerNav'>
                <Navbar.Brand>
                    <Navbar.Item renderAs="a" href="/">
                        <img src={logo} alt="Alcom: Kursside" />
                    </Navbar.Item>
                    <Navbar.Burger active={activeBurger} onClick={() => setActiveBurger(!activeBurger)} />
                </Navbar.Brand>
                <Navbar.Menu >
                    <Navbar.Container>
                        {
                            courses.length !== 0 && courses !== null && loggedIn?
                                <Navbar.Item dropdown hoverable href="#">
                                    <Navbar.Link arrowless={false}>
                                        Kurs
                                    </Navbar.Link>
                                    <Navbar.Dropdown>
                                        {
                                            courses.map((course, key) => {
                                                return (
                                                    <Navbar.Item 
                                                        key={key} 
                                                        href={null}
                                                        onClick={() => {
                                                            history.push('/courses/' + course.id);
                                                        }}
                                                    >
                                                        {course.name}
                                                    </Navbar.Item>
                                                );
                                            })
                                        }
                                    </Navbar.Dropdown>
                                </Navbar.Item>
                                :
                                null
                        }
                        {
                            isAdmin?
                                <Navbar.Item dropdown hoverable href="#">
                                    <Navbar.Link arrowless={false}>
                                        Admin
                                    </Navbar.Link>
                                    <Navbar.Dropdown>
                                        <Navbar.Item
                                            href={null}
                                            onClick={() => {
                                                history.push('/admin/courses');
                                            }}
                                        >
                                            Kurs
                                        </Navbar.Item>
                                        <Navbar.Item
                                            href={null}
                                            onClick={() => {
                                                history.push('/admin/users');
                                            }}
                                        >
                                            Brukere
                                        </Navbar.Item>
                                    </Navbar.Dropdown>
                                </Navbar.Item>
                                :
                                null
                        }
                        {
                            loggedIn ? 
                                <Navbar.Item href={null} onClick={() => {
                                    Auth.signOut()
                                    .then((res) => {
                                        setLoggedIn(false);
                                        setIsAdmin(false);
                                        history.push('/login')
                                    })
                                    .catch((e) => console.log(e));
                                    
                                }}>
                                    <Navbar.Link arrowless={true}>
                                        Logg ut
                                    </Navbar.Link>
                                </Navbar.Item>
                            : null
                        }
                    </Navbar.Container>
                </Navbar.Menu>
            </Navbar>
        </div>
    );
}

export default Header
