import { autoShowTooltip } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { Container, Section, Heading, Columns, List, Level } from 'react-bulma-components';

const CourseContent = props => {


    const handleDownloadFile = (file) => {
        if(file.includes('.txt') || file.includes('.blockly') || file.includes('.py') ) {
            fetch(file,{
                method: 'GET',
            }).then((res) => res.text())
            .then((res) => {
                let blob = new Blob([res],{type:'text/plain'});
                let url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download',file.replace('https://alcomcoursecodefiles.s3-eu-west-1.amazonaws.com/',''));
                link.target = '_blank'
                link.click();
            })
        } else {
            const link = document.createElement('a');
            link.href = file;
            link.target = '_blank'
            link.click();
        }
    }


    if (props.course !== null) {
        return (
            <Columns className="is-centered">
                <Columns.Column className='is-8'>
                    {
                        props.course.articles.map((article, key) => {
                            return (
                                <div>
                                <Section key={key}>
                                    <Container>
                                        <Section>
                                            <Container>
                                                <Heading size={6}>
                                                    {key + 1 + '. ' + article.title}
                                                </Heading>
                                            </Container>
                                            {
                                                article.description !== '' ?
                                                    <Section>
                                                    <Container>
                                                        {article.description}
                                                    </Container>
                                                    </Section>
                                                : null
                                            }
                                        </Section>
                                        {
                                            article.video !== '' ?
                                            <Section style={{ textAlign: 'center' }}>
                                                <Container>
                                                    <iframe
                                                        src={article.video}
                                                        width="640"
                                                        height="360"
                                                        frameBorder="0"
                                                        allow="autoplay; fullscreen"
                                                        allowFullScreen
                                                    >
                                                    </iframe>
                                                </Container>
                                            </Section>
                                            : null
                                        }
                                        {
                                        article.codeFiles.length !== 0 ?
                                            <Section>
                                                <Heading size={6}>
                                                    Kodefiler
                                                    </Heading>
                                                <List>
                                                    {
                                                        article.codeFiles.map((file, filekey) => {
                                                            return (
                                                                <List.Item key={filekey}>
                                                                    <Level>
                                                                        <Level.Side align='left'>
                                                                            <Level.Item>
                                                                                {file.replace('https://alcomcoursecodefiles.s3-eu-west-1.amazonaws.com/','')}
                                                                            </Level.Item>
                                                                        </Level.Side>
                                                                        <Level.Side align='right'>
                                                                            <Level.Item>
                                                                                <a onClick={() => {handleDownloadFile(file)}}>Last ned</a>
                                                                            </Level.Item>
                                                                        </Level.Side>
                                                                    </Level>
                                                                </List.Item>
                                                            );
                                                        })
                                                    }
                                                </List>
                                            </Section>
                                            :
                                            null
                                        }
                                        {
                                            article.docFiles.length !== 0 ?
                                            <Section>
                                                <Heading size={6}>
                                                    Dokumenter
                                                </Heading>
                                                <List>
                                                    {
                                                        article.docFiles.map((file, filekey) => {
                                                            return (
                                                                <List.Item key={filekey}>
                                                                    <Level>
                                                                        <Level.Side align='left'>
                                                                            <Level.Item>
                                                                                {file.replace('https://alcomcoursedocfiles.s3-eu-west-1.amazonaws.com/','')}
                                                                            </Level.Item>
                                                                        </Level.Side>
                                                                        <Level.Side align='right'>
                                                                            <Level.Item>
                                                                                <a href={file} target='_blank'>Last ned</a>
                                                                            </Level.Item>
                                                                        </Level.Side>
                                                                    </Level>
                                                                </List.Item>
                                                            );
                                                        })
                                                    }
                                                </List>
                                            </Section>
                                            :null
                                        }
                                    </Container>
                                </Section>
                                 <hr style={{ border: '1px solid #5cc1a1' }} />
                                 </div>
                            );
                        })
                    }
                </Columns.Column>
            </Columns>
        );
    } else {
        return (
            null
        );
    }
}

export default CourseContent
