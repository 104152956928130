/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:695a1559-53d5-4f0f-b595-df6ebf23b47c",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_Jq06RAggC",
    "aws_user_pools_web_client_id": "456ugjj58acf2k2u062qtsrodn",
    "oauth": {}
};


export default awsmobile;
