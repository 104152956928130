import React, { useContext, useEffect, useState } from 'react';
import CourseContent from './Content';
import { CourseContext } from './contexts/CourseContext'
import { Container, Heading, Section } from 'react-bulma-components';
import {Auth} from 'aws-amplify';
import { useHistory } from 'react-router-dom';

const Course = props => {
    const [courses, delCourse, getCourse] = useContext(CourseContext);
    const [course, setCourse] = useState(null);
    const history = useHistory();

    useEffect(() => {
        Auth.currentAuthenticatedUser()
        .then((user) => {})
        .catch((e) => {
            history.push('/login');
        });
    },[])

    useEffect(() => {
        setCourse(getCourse(props.history.location.pathname.split('/')[2]));
    },[props.history.location])

    if (course !== null){
        return (
            <Section>
            <Container>
                <Heading style={{textAlign:'center'}}>{course.name}</Heading>
                <CourseContent course={course} />
            </Container>
            </Section>
        );
    } else {
        return (
            <Container>
                Ugyldig kurs
            </Container>
        );
    }
}

export default Course
