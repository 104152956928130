import React, { useEffect, useState, useContext } from 'react';
import { Section, Button, Container, Heading} from 'react-bulma-components';
import { StatusContext } from './contexts/StatusContext'
import {Auth} from 'aws-amplify';
import { useHistory } from 'react-router-dom';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loggedIn, setLoggedIn, isAdmin, setIsAdmin, updateHeader, setUpdateHeader ] = useContext(StatusContext);
    const history = useHistory();

    useEffect(() => {
        Auth.currentAuthenticatedUser()
        .then((user) => {
            setUpdateHeader(!updateHeader);
            history.push('/');
        })
    },[])

 
    const handleAuthenticate = async () => {
        Auth.signIn(email.toLowerCase(),password)
        .then(user => {
            setUpdateHeader(!updateHeader);
            history.push('/');
        }).catch((e) => {
            if(e.code === 'NotAuthorizedException') {
                alert('FEIL: Feil brukernavn eller passord!')
            }
        });
        
    }

    return (
        <Section >
            <Container style={{maxWidth:'40%', marginTop:'2em'}}>
                <Heading size={4}>Innlogging</Heading> 
            </Container>
            <Container style={{maxWidth:'40%', marginTop:'2em'}}>
                <div className='field'>
                    <div className='label'>Epost</div>
                    <div className='control'>
                        <input type='text' className='input' onChange={(e) => { setEmail(e.target.value) }} />
                    </div>
                </div>
                <div className='field'>
                    <div className='label'>Passord</div>
                    <div className='control'>
                        <input type='password' className='input' onChange={(e) => { setPassword(e.target.value) }} />
                    </div>
                </div>
                <a
                    onClick={() => history.push('/login/forgot')}
                >
                    Glemt passord?
                </a>
                <Section>
                <Button 
                    className="is-info"
                    onClick={() => handleAuthenticate()}
                >
                    Logg in
                </Button>
                </Section>
            </Container>
        </Section>
    );
}

export default Login;
