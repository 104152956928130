import React, { useEffect, useRef, useState } from 'react';
import { Section, Heading, Container, Table, Level, Modal, Button, Dropdown, Columns } from 'react-bulma-components';
import { AiOutlineDelete, AiOutlineEdit, AiOutlineReload} from 'react-icons/ai';
import { useHistory } from 'react-router-dom';
import { BounceLoader, ClipLoader } from 'react-spinners';
import AdminNewUser from './AdminNewUser';


const AdminUsers = () => {
    const [dataSource, setDataSource] = useState([])
    const [searchDataSource, setSearchDataSource] = useState([])
    const [newUser, setNewUser] = useState(false);
    const [update, setUpdate] = useState(false);
    const [deleteUser, setDeleteUser] = useState(null);
    const [deleting, setDeleting] = useState(false);
    const [editUser, setEditUser] = useState(null);
    const [selectedEditRole, setSelectedEditRole] = useState('');
    const [editing, setEditing] = useState(false);
    const [resendInvite, setResendInvite] = useState(null);
    const [resending, setResending] = useState(false);
    const [getingUsers, setGetingUsers] = useState(false)
    const [searchTerm, setSearchTerm] = useState('');
    const [searching, setSearching] = useState(false);
    const searchRef = useRef();
    const history = useHistory();

    useEffect(() => {

        let getUsers = async () => {
            setGetingUsers(true);
            let usersRes = await fetch('https://q9dyambaka.execute-api.eu-west-1.amazonaws.com/prod/users', {
                method: 'GET'
            }).then((res) => res.json())
            let temp = [...dataSource]
            for (let u in usersRes.body.Users) {

                let groups = await handleGetUserGroups(usersRes.body.Users[u].Username);
                temp.push(
                    {
                        username: usersRes.body.Users[u].Username,
                        email: usersRes.body.Users[u].Attributes[2].Value,
                        userCreateDate: usersRes.body.Users[u].UserCreateDate,
                        userStatus: usersRes.body.Users[u].UserStatus === 'FORCE_CHANGE_PASSWORD' ? 'Ubekreftet' : usersRes.body.Users[u].UserStatus === 'CONFIRMED' ? 'Bekreftet' : usersRes.body.Users[u].UserStatus,
                        groups: groups
                    }
                )

            }

            setDataSource(temp)
            setGetingUsers(false);
        }

        getUsers();

    }, [update])

    useEffect(() => {
        setUpdate(!update);
    }, [])


    const emailComparator = (a, b) => {
        if (a.email < b.email) return -1
        if (a.email > b.email) return 1
        return 0
    }

    const emailCreateDate = (a, b) => {
        if (a.userCreateDate < b.userCreateDate) return -1
        if (a.userCreateDate > b.userCreateDate) return 1
        return 0
    }

    const handleGetUserGroups = async (username) => {
        return new Promise(resolve => {
            fetch('https://q9dyambaka.execute-api.eu-west-1.amazonaws.com/prod/groups/' + username, {
                method: 'GET'
            }).then((res) => res.json())
                .then((res) => {
                    let groups = []
                    for (let g in res.body.Groups) {
                        groups.push(res.body.Groups[g].GroupName)
                    }
                    resolve(groups);
                })
        });
    }

    const handleDeleteUser = () => {
        setDeleting(true);
        fetch('https://q9dyambaka.execute-api.eu-west-1.amazonaws.com/prod/users', {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                username: deleteUser.username
            })
        }).then((res) => res.json())
            .then((res) => {
                setDeleting(false);
                setDeleteUser(null);
                setUpdate(!update);
            })
    }

    const handleEditUser = () => {

        if (selectedEditRole === '' || editUser.groups[0] === selectedEditRole) {
            return null;
        }

        setEditing(true);

        fetch('https://q9dyambaka.execute-api.eu-west-1.amazonaws.com/prod/users', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                username: editUser.username,
                oldGroup: editUser.groups[0],
                newGroup: selectedEditRole
            })
        }).then((res) => res.json())
            .then((res) => {
                setEditing(false);
                setSelectedEditRole('');
                setEditUser(null);
                setUpdate(!update);
            })
    }

    const handleSearch = () => {
        console.log('')
        if (searchTerm.length === 0) {
            return;
        }
        let temp = [];
        for (let i = 0; i < dataSource.length; i++) {
            if (dataSource[i].email.includes(searchTerm.trim().toLowerCase())) {
                temp.push(dataSource[i])
            }
        }
        setSearchDataSource(temp);
        setSearching(true);
    }

    const handleResendInvite = () => {
    
        setResending(true);

        fetch('https://q9dyambaka.execute-api.eu-west-1.amazonaws.com/prod/users/resend', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: resendInvite.email
            })
        }).then((res) => res.json())
            .then((res) => {
                console.log(res);
                setResending(false);
                setResendInvite(null);
            })
    }

    return (
        <Section>
            <Container>
                <Section>
                    <Heading>Brukere</Heading>
                    <Section>
                        <Container>
                            <Columns>
                                <Columns.Column size={3}>
                                    <div className='field has-addons'>
                                        <div class="control">
                                            <input ref={searchRef} className='input' type='text' placeholder='Søk' onChange={(e) => { setSearchTerm(e.target.value) }} />
                                        </div>
                                        <div class="control">
                                            <Button className='is-success'
                                                onClick={() => handleSearch()}
                                            >
                                                Søk
                                            </Button>
                                        </div>
                                        
                                        {
                                        searching?
                                            <div className='control'>
                                                <Button                                 
                                                    className='is-danger'
                                                    onClick={() => {
                                                        searchRef.current.value = '';
                                                        setSearchDataSource([]);
                                                        setSearchTerm('');
                                                        setSearching(false);
                                                    }}
                                                >
                                                    Nullstill søk
                                                </Button>
                                            </div>
                                        : null
                                    }
                                    </div>
                                </Columns.Column>
                                <Columns.Column size={6} />
                                <Columns.Column size={3}>
                                    {
                                        !newUser ?
                                            <Container
                                                style={{textAlign:'right'}}
                                            >
                                                <Button
                                                    color="info"
                                                    onClick={() => setNewUser(true)}
                                                >
                                                    + Ny Bruker
                                                </Button>
                                            </Container>
                                        : null
                                    }
                                </Columns.Column>
                            </Columns>
                        </Container>
                    </Section>
                    {
                        newUser ?
                            <AdminNewUser setNewUser={setNewUser} update={[update, setUpdate]} />
                        : null
                    }
                    <Container>
                        <Table>
                            <thead>
                                <tr>
                                    <th>
                                        <Level>
                                            <Level.Side align="left">
                                                <Level.Item>Brukernavn</Level.Item>
                                            </Level.Side>
                                        </Level>
                                    </th>
                                    <th>Opprettet</th>
                                    <th>Status</th>
                                    <th>Rolle</th>
                                    <th></th>
                                </tr>
                            </thead>
                            {
                                !searching ?
                                    <tbody>
                                        {
                                            getingUsers ?
                                                <tr>
                                                    <td colSpan="5">
                                                        <Level>
                                                            <Level.Item>
                                                                <BounceLoader
                                                                    size={150}
                                                                    color={"#5cc1a1"}
                                                                />
                                                            </Level.Item>
                                                        </Level>
                                                    </td>
                                                </tr>
                                            : null
                                        }
                                        {
                                            dataSource.length !== 0 ?
                                                dataSource.map((user, key) => {
                                                    return (
                                                        <tr key={key}>
                                                            <td>{user.email}</td>
                                                            <td>{user.userCreateDate}</td>
                                                            <td>{user.userStatus}</td>
                                                            {
                                                                editUser === null || editUser.username !== user.username ?
                                                                    <td>{user.groups[0] === 'teacher' ? 'Lærer' : user.groups[0] === 'admin' ? 'Admin' : user.groups[0] === 'student' ? 'Elev' : 'Test'}</td>
                                                                    :
                                                                    <td>
                                                                        {
                                                                            user.groups[0] === 'teacher' ?
                                                                                <Dropdown
                                                                                    value={selectedEditRole}
                                                                                    onChange={(selected) => setSelectedEditRole(selected)}
                                                                                >
                                                                                    <Dropdown.Item
                                                                                        style={{ backgroundColor: '#5cc1a1' }}
                                                                                        value='teacher'
                                                                                    >
                                                                                        Lærer
                                                                                </Dropdown.Item>
                                                                                    <Dropdown.Item
                                                                                        value='student'
                                                                                    >
                                                                                        Elev
                                                                                </Dropdown.Item>
                                                                                    <Dropdown.Item
                                                                                        value='admin'
                                                                                    >
                                                                                        Admin
                                                                                </Dropdown.Item>
                                                                                </Dropdown>
                                                                                :
                                                                                user.groups[0] === 'student' ?
                                                                                    <Dropdown
                                                                                        value={selectedEditRole}
                                                                                        onChange={(selected) => setSelectedEditRole(selected)}
                                                                                    >
                                                                                        <Dropdown.Item
                                                                                            style={{ backgroundColor: '#5cc1a1' }}
                                                                                            value='student'
                                                                                        >
                                                                                            Elev
                                                                                    </Dropdown.Item>
                                                                                        <Dropdown.Item
                                                                                            value='teacher'
                                                                                        >
                                                                                            Lærer
                                                                                    </Dropdown.Item>
                                                                                        <Dropdown.Item
                                                                                            value='admin'
                                                                                        >
                                                                                            Admin
                                                                                    </Dropdown.Item>
                                                                                    </Dropdown>
                                                                                    :
                                                                                    user.groups[0] === 'admin' ?
                                                                                        <Dropdown
                                                                                            value={selectedEditRole}
                                                                                            onChange={(selected) => setSelectedEditRole(selected)}
                                                                                        >
                                                                                            <Dropdown.Item
                                                                                                style={{ backgroundColor: '#5cc1a1' }}
                                                                                                value='admin'
                                                                                            >
                                                                                                Admin
                                                                                    </Dropdown.Item>
                                                                                            <Dropdown.Item
                                                                                                value='teacher'
                                                                                            >
                                                                                                Lærer
                                                                                    </Dropdown.Item>
                                                                                            <Dropdown.Item
                                                                                                value='student'
                                                                                            >
                                                                                                Elev
                                                                                    </Dropdown.Item>
                                                                                        </Dropdown>
                                                                                        :
                                                                                        null
                                                                        }
                                                                    </td>
                                                            }
                                                            <td>
                                                                <Level>
                                                                    <Level.Side align="right">
                                                                        <Level.Item style={{ cursor: 'pointer' }} onClick={() => {
                                                                            setDeleteUser(user)
                                                                        }}>
                                                                            <AiOutlineDelete size={20} />
                                                                        </Level.Item>
                                                                        {
                                                                            user.userStatus === 'Ubekreftet' ? 
                                                                                <Level.Item style={{ cursor: 'pointer' }} onClick={() => {
                                                                                    setResendInvite(user)
                                                                                }}>
                                                                                    <AiOutlineReload size={20} />
                                                                                </Level.Item>
                                                                            : null
                                                                        }
                                                                        {
                                                                            editUser === null || editUser.username !== user.username ?
                                                                                <Level.Item style={{ cursor: 'pointer' }} onClick={() => {
                                                                                    setEditUser(user)
                                                                                }}>
                                                                                    <AiOutlineEdit size={20} />
                                                                                </Level.Item>
                                                                                :
                                                                                <Level.Item>
                                                                                    <Button
                                                                                        color='success'
                                                                                        loading={editing}
                                                                                        onClick={() => handleEditUser()}
                                                                                    >
                                                                                        Lagre
                                                                                </Button>
                                                                                    <Button
                                                                                        color='danger'
                                                                                        style={{ marginLeft: '1em' }}
                                                                                        onClick={() => {
                                                                                            setEditUser(null)
                                                                                            setSelectedEditRole('')
                                                                                        }}
                                                                                    >
                                                                                        Avbryt
                                                                                </Button>
                                                                                </Level.Item>

                                                                        }
                                                                    </Level.Side>
                                                                </Level>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                                :
                                                null
                                        }
                                    </tbody>
                                    :
                                    <tbody>
                                        {
                                            getingUsers ?
                                                <tr>
                                                    <td colSpan="5">
                                                        <Level>
                                                            <Level.Item>
                                                                <BounceLoader
                                                                    size={150}
                                                                    color={"#5cc1a1"}
                                                                />
                                                            </Level.Item>
                                                        </Level>

                                                    </td>
                                                </tr>
                                                : null
                                        }
                                        {
                                            dataSource.length !== 0 ?
                                                searchDataSource.map((user, key) => {
                                                    return (
                                                        <tr key={key}>
                                                            <td>{user.email}</td>
                                                            <td>{user.userCreateDate}</td>
                                                            <td>{user.userStatus}</td>
                                                            {
                                                                editUser === null || editUser.username !== user.username ?
                                                                    <td>{user.groups[0] === 'teacher' ? 'Lærer' : user.groups[0] === 'admin' ? 'Admin' : user.groups[0] === 'student' ? 'Elev' : 'Test'}</td>
                                                                    :
                                                                    <td>
                                                                        {
                                                                            user.groups[0] === 'teacher' ?
                                                                                <Dropdown
                                                                                    value={selectedEditRole}
                                                                                    onChange={(selected) => setSelectedEditRole(selected)}
                                                                                >
                                                                                    <Dropdown.Item
                                                                                        style={{ backgroundColor: '#5cc1a1' }}
                                                                                        value='teacher'
                                                                                    >
                                                                                        Lærer
                                                                                    </Dropdown.Item>
                                                                                    <Dropdown.Item
                                                                                        value='student'
                                                                                    >
                                                                                        Elev
                                                                                    </Dropdown.Item>
                                                                                    <Dropdown.Item
                                                                                        value='admin'
                                                                                    >
                                                                                        Admin
                                                                                    </Dropdown.Item>
                                                                                </Dropdown>
                                                                                :
                                                                                user.groups[0] === 'student' ?
                                                                                    <Dropdown
                                                                                        value={selectedEditRole}
                                                                                        onChange={(selected) => setSelectedEditRole(selected)}
                                                                                    >
                                                                                        <Dropdown.Item
                                                                                            style={{ backgroundColor: '#5cc1a1' }}
                                                                                            value='student'
                                                                                        >
                                                                                            Elev
                                                                                        </Dropdown.Item>
                                                                                        <Dropdown.Item
                                                                                            value='teacher'
                                                                                        >
                                                                                            Lærer
                                                                                        </Dropdown.Item>
                                                                                        <Dropdown.Item
                                                                                            value='admin'
                                                                                        >
                                                                                            Admin
                                                                                        </Dropdown.Item>
                                                                                    </Dropdown>
                                                                                    :
                                                                                    user.groups[0] === 'admin' ?
                                                                                        <Dropdown
                                                                                            value={selectedEditRole}
                                                                                            onChange={(selected) => setSelectedEditRole(selected)}
                                                                                        >
                                                                                            <Dropdown.Item
                                                                                                style={{ backgroundColor: '#5cc1a1' }}
                                                                                                value='admin'
                                                                                            >
                                                                                                Admin
                                                                                            </Dropdown.Item>
                                                                                            <Dropdown.Item
                                                                                                value='teacher'
                                                                                            >
                                                                                                Lærer
                                                                                            </Dropdown.Item>
                                                                                            <Dropdown.Item
                                                                                                value='student'
                                                                                            >
                                                                                                Elev
                                                                                            </Dropdown.Item>
                                                                                        </Dropdown>
                                                                                    :
                                                                                        null
                                                                        }
                                                                    </td>
                                                            }
                                                            <td>
                                                                <Level>
                                                                    <Level.Side align="right">
                                                                        <Level.Item style={{ cursor: 'pointer' }} onClick={() => {
                                                                            setDeleteUser(user)
                                                                        }}>
                                                                            <AiOutlineDelete size={20} />
                                                                        </Level.Item>
                                                                        {
                                                                            user.userStatus === 'Ubekreftet' ? 
                                                                                <Level.Item style={{ cursor: 'pointer' }} onClick={() => {
                                                                                    setResendInvite(user)
                                                                                }}>
                                                                                    <AiOutlineReload size={20} />
                                                                                </Level.Item>
                                                                            : null
                                                                        }
                                                                        {
                                                                            editUser === null || editUser.username !== user.username ?
                                                                                <Level.Item style={{ cursor: 'pointer' }} onClick={() => {
                                                                                    setEditUser(user)
                                                                                }}>
                                                                                    <AiOutlineEdit size={20} />
                                                                                </Level.Item>
                                                                                :
                                                                                <Level.Item>
                                                                                    <Button
                                                                                        color='success'
                                                                                        loading={editing}
                                                                                        onClick={() => handleEditUser()}
                                                                                    >
                                                                                        Lagre
                                                                    </Button>
                                                                                    <Button
                                                                                        color='danger'
                                                                                        style={{ marginLeft: '1em' }}
                                                                                        onClick={() => {
                                                                                            setEditUser(null)
                                                                                            setSelectedEditRole('')
                                                                                        }}
                                                                                    >
                                                                                        Avbryt
                                                                    </Button>
                                                                                </Level.Item>

                                                                        }
                                                                    </Level.Side>
                                                                </Level>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                                :
                                                null
                                        }
                                    </tbody>
                            }
                        </Table>
                    </Container>
                    {
                        deleteUser !== null ?
                            <Modal
                                show={deleteUser !== null ? true : false}
                                onClose={() => setDeleteUser(null)}
                            >
                                <Modal.Content>
                                    <Section style={{ backgroundColor: 'white' }} >
                                        <Container>
                                            <Heading size={5}>Slette bruker?</Heading>
                                            {'Vil du slette bruker: ' + deleteUser.email}
                                            <Section>
                                                <Container>
                                                    <Button
                                                        loading={deleting ? true : false}
                                                        color="success"
                                                        onClick={() => handleDeleteUser()}
                                                    >
                                                        Slett
                                                </Button>
                                                    <Button
                                                        color="danger"
                                                        style={{ marginLeft: '1em' }}
                                                        onClick={() => setDeleteUser(null)}
                                                    >
                                                        Avbryt
                                                </Button>
                                                </Container>
                                            </Section>
                                        </Container>
                                    </Section>
                                </Modal.Content>
                            </Modal>
                            : null
                    }
                     {
                        resendInvite !== null ?
                            <Modal
                                show={resendInvite !== null ? true : false}
                                onClose={() => setResendInvite(null)}
                            >
                                <Modal.Content>
                                    <Section style={{ backgroundColor: 'white' }} >
                                        <Container>
                                            <Heading size={5}>Ny invitasjon!</Heading>
                                            {'Vil du sende ny invitasjon til bruker: ' + resendInvite.email}
                                            <Section>
                                                <Container>
                                                    <Button
                                                        loading={resending ? true : false}
                                                        color="success"
                                                        onClick={() => handleResendInvite()}
                                                    >
                                                        Send
                                                </Button>
                                                    <Button
                                                        color="danger"
                                                        style={{ marginLeft: '1em' }}
                                                        onClick={() => setResendInvite(null)}
                                                    >
                                                        Avbryt
                                                </Button>
                                                </Container>
                                            </Section>
                                        </Container>
                                    </Section>
                                </Modal.Content>
                            </Modal>
                            : null
                    }
                </Section>
            </Container>
        </Section>
    );

}

export default AdminUsers;
