import React, {useState, createContext, useEffect} from 'react';

export const CourseContext = createContext();

export const CourseContextProvider = props => {
    const [courses, setCourses] =  useState([]);
    const [update, setUpdate] =  useState(false);


    const sortCourses = (arr) => {
        arr.sort((a,b) => {
            if(a.index < b.index) return -1;
            if(a.index > b.index) return 1;
            return 0;
        })
    }

    const sortArticles = (arr) => {
        arr.sort((a,b) => {
            if(a.index < b.index) return -1;
            if(a.index > b.index) return 1;
            return 0;
        })
    }

    const getCourse = (courseId) =>{
        for(let c in courses) {
            if(courses[c].id === courseId) {
                return courses[c];
            }
        }
        return null;
    }
    

    const delCourse = (courseId) => {
        let temp = [];
        for(let c in courses) {
            if(courses[c].id !== courseId) {
                temp.push(courses[c])
            }
        }
        setCourses(temp);
    }

    useEffect(() => {
        setCourses([]);
        let temp = [];
        fetch("https://q9dyambaka.execute-api.eu-west-1.amazonaws.com/prod/courses",{
            method: 'GET'
        }).then(res => res.json())
        .then(res => {
            let counter = 0;
            let length = res.body.length;
            
            for(let c in res.body) {
                fetch("https://q9dyambaka.execute-api.eu-west-1.amazonaws.com/prod/courses/" + res.body[c],{
                    method: 'GET'
                }).then(res => res.json())
                .then(res => {
                    counter++;
                    res.body.checked = false;
                    sortArticles(res.body.articles);
                    temp.push(res.body);
                    if(counter === length) {
                        sortCourses(temp);
                        setCourses(temp);
                    }
                })
            }
        })
    },[,update])

    return (
        <CourseContext.Provider value={[courses, delCourse, getCourse, setCourses, update, setUpdate]}>
            {props.children}
        </CourseContext.Provider>
    );
}