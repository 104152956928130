import React, { useContext, useState } from 'react';
import { CourseContext } from './contexts/CourseContext';
import { Level, List, Modal, Section, Button, Heading, Container } from 'react-bulma-components';
import { AiOutlineArrowDown, AiOutlineArrowUp, AiOutlineDelete, AiOutlineEdit, AiOutlinePlusCircle } from 'react-icons/ai';
import sha256 from 'crypto-js/sha256';
import { useHistory } from 'react-router-dom';


const AdminCourses = () => {

    const [courses, delCourse, getCourse, setCourses,update, setUpdate] = useContext(CourseContext);
    const [delModal, setDelModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [createModal, setCreateModal] = useState(false);
    const [createCourseName, setCreateCourseName] = useState('');
    const [selectedCourse, setSelectedCourse] = useState(courses[0]);
    const [showAdminCourse, setShowAdminCourse] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [creating, setCreating] = useState(false);
    const history = useHistory();

    const deleteCourse = (id) => {
        setDeleting(true);
        fetch("https://q9dyambaka.execute-api.eu-west-1.amazonaws.com/prod/courses/" + id, {
            method: 'DELETE'
        }).then(res => res.json())
            .then(res => {
                setDeleting(false);
                setDelModal(false);
                delCourse(id);
            });
    }

    const updateCourse = (id, course) => {
        setUpdating(true);
        fetch("https://q9dyambaka.execute-api.eu-west-1.amazonaws.com/prod/courses/" + id, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                course: course
            })
        }).then(res => res.json())
            .then(res => {
                let temp = [...courses];
                setCourses(temp);
                setUpdating(false);
                setEditModal(false);
            });
    }

    const createCourse = () => {
        setCreating(true);
        fetch("https://q9dyambaka.execute-api.eu-west-1.amazonaws.com/prod/courses/", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id: sha256(createCourseName + Date.now()).toString(),
                name: createCourseName,
                articles: [],
                index: courses.length

            })
        }).then(res => res.json())
            .then(res => {
                let temp = [...courses];
                temp.push(JSON.parse(res.body))
                setCourses(temp);
                setCreating(false);
                setCreateModal(false);
            });
    }

    const handleMoveCourseUp = async (course) => {
        
        let courseCopy = { ...course };
        courseCopy.index--;

        let affectedCourse = null;

        for(let i = 0; i < courses.length; i++) {
            if(courses[i].index === courseCopy.index) {
               
                affectedCourse = { ...courses[i] };
            }
        }

        if(affectedCourse === null) {
            affectedCourse = { index: 'NaN' };
        }

        affectedCourse.index++;


        
        await fetch('https://q9dyambaka.execute-api.eu-west-1.amazonaws.com/prod/courses/' + courseCopy.id,{
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                course: courseCopy
            })
        })

        await fetch('https://q9dyambaka.execute-api.eu-west-1.amazonaws.com/prod/courses/' + affectedCourse.id,{
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                course: affectedCourse
            })
        })

        setUpdate(!update);
    }

    const handleMoveCourseDown = async (course) => {
        
        let courseCopy = { ...course };
        courseCopy.index++;

        let affectedCourse = null;

        for(let i = 0; i < courses.length; i++) {
            if(courses[i].index === courseCopy.index) {
               
                affectedCourse = { ...courses[i] };
            }
        }

        if(affectedCourse === null) {
            affectedCourse = { index: 'NaN' };
        }

        affectedCourse.index--;


        
        await fetch('https://q9dyambaka.execute-api.eu-west-1.amazonaws.com/prod/courses/' + courseCopy.id,{
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                course: courseCopy
            })
        })

        await fetch('https://q9dyambaka.execute-api.eu-west-1.amazonaws.com/prod/courses/' + affectedCourse.id,{
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                course: affectedCourse
            })
        })

        setUpdate(!update);
    }
    
    return (
        <Section>
            <Container>
                <Section>
                    <Heading>Kurs</Heading>
                    {
                    courses.length !== 0?
                    <List>
                        {
                            courses.map((course, key) => {
                                return (
                                    <List.Item key={key}>
                                        <Level>
                                            <Level.Side align='left'>
                                                {
                                                    !editModal || selectedCourse.id !== course.id ? 
                                                        <Level.Item>
                                                            <a onClick={() => {
                                                                history.push({
                                                                    pathname: '/admin/course/' + course.id,
                                                                    state:{
                                                                        course: course
                                                                    }
                                                                });
                                                            }}>
                                                                {course.name}
                                                            </a>
                                                        </Level.Item>
                                                    :
                                                    <Level.Item>
                                                           <div className='control'>
                                                                <input className='input' type='text' defaultValue={selectedCourse.name} onChange={(e) => { selectedCourse.name = e.target.value }} />
                                                            </div>
                                                    </Level.Item>
                                                }
                                            </Level.Side>
                                            <Level.Side align='right'>
                                                <Level.Item style={{ cursor: 'pointer' }}>
                                                    <AiOutlineEdit
                                                        size='1.5em'
                                                        onClick={() => {
                                                            setSelectedCourse(course);
                                                            setEditModal(true);

                                                        }}
                                                    />
                                                </Level.Item>
                                                <Level.Item style={{ cursor: 'pointer' }}>
                                                    <AiOutlineDelete
                                                        size='1.5em'
                                                        onClick={() => {
                                                            setSelectedCourse(course);
                                                            setDelModal(true);
                                                        }}
                                                    />
                                                </Level.Item>
                                                {
                                                    key !== 0 ? 
                                                    <Level.Item style={{ cursor: 'pointer' }}>
                                                        
                                                        <AiOutlineArrowUp 
                                                            size='1.5em'
                                                            onClick={() => handleMoveCourseUp(course)}
                                                        />
                                                        
                                                        
                                                    </Level.Item>
                                                    : null
                                                }
                                                {
                                                    key !== courses.length-1 ? 
                                                    <Level.Item style={{ cursor: 'pointer' }}>
                                                        
                                                        <AiOutlineArrowDown 
                                                            size='1.5em'
                                                            onClick={() => handleMoveCourseDown(course)}
                                                        />
                                                        
                                                        
                                                    </Level.Item>
                                                    : null
                                                }
                                                {
                                                    editModal &&  selectedCourse.id === course.id ? 
                                                        <Level.Item>
                                                            <Button
                                                                color='success'
                                                                loading={updating}
                                                                onClick={() => updateCourse(course.id, course)}
                                                            >
                                                                Lagre
                                                            </Button>
                                                        </Level.Item>
                                                    : 
                                                    null
                                                }
                                                                                                {
                                                    editModal && selectedCourse.id === course.id ? 
                                                        <Level.Item>
                                                            <Button 
                                                                color='danger'
                                                                onClick={() => {
                                                                    setEditModal(false);
                                                                }}
                                                            >
                                                                Avbryt
                                                            </Button>
                                                        </Level.Item>
                                                    :
                                                    null
                                                }
                                            </Level.Side>
                                        </Level>
                                    </List.Item>
                                );
                            })

                        }
                        {
                            createModal ? 
                                <List.Item>
                                    <Section>
                                        <Heading size={5} >Opprette kurs</Heading>
                                        <Container>
                                            <div className='field'>
                                                <div className='label' >Kursnavn</div>
                                                <div className='control'>
                                                    <input className='input' type='text' onChange={(e) => { setCreateCourseName(e.target.value) }} />
                                                </div>
                                            </div>
                                            <Level>
                                                <Level.Side align='left'>
                                                    <Level.Item>
                                                        <Button
                                                            disabled={createCourseName.length > 0 ? false : true}
                                                            color='success'
                                                            loading={creating}
                                                            onClick={() => { createCourse() }}
                                                        >
                                                            Lagre
                                                        </Button>
                                                    </Level.Item>
                                                    <Level.Item>
                                                        <Button
                                                            color='danger'
                                                            onClick={() => setCreateModal(false)}
                                                        >
                                                            Avbryt
                                                        </Button>
                                                    </Level.Item>
                                                </Level.Side>
                                            </Level>
                                        </Container>
                                    </Section>
                                </List.Item>
                            : null
                        }
                        <List.Item>
                            <Level>
                                <Level.Item style={{ cursor: 'pointer' }}>
                                    <AiOutlinePlusCircle
                                        size='1.5em'
                                        onClick={() => setCreateModal(true)}
                                    />
                                </Level.Item>
                            </Level>
                        </List.Item>
                        
                        <Modal
                            show={delModal}
                            onClose={() => setDelModal(false)}
                        >
                            <Modal.Content>
                                <Section style={{ backgroundColor: 'white' }} >
                                    <Section>
                                        Vil du slette kurset {selectedCourse.name}?
                                    </Section>
                                    <Section>
                                        <Level>
                                            <Level.Side align='left'>
                                                <Level.Item>
                                                    <Button
                                                        color='success'
                                                        loading={deleting}
                                                        onClick={() => { deleteCourse(selectedCourse.id) }}
                                                    >
                                                        OK
                                                </Button>
                                                </Level.Item>
                                                <Level.Item>
                                                    <Button
                                                        color='danger'
                                                        onClick={() => setDelModal(false)}
                                                    >
                                                        Avbryt
                                                    </Button>
                                                </Level.Item>
                                            </Level.Side>
                                        </Level>
                                    </Section>
                                </Section>
                            </Modal.Content>
                        </Modal>
                    </List>
                    :
                    <List>
                        <List.Item>
                            Ingen kurs opprettet
                        </List.Item>
                    </List>
                    }
                </Section>
            </Container>
        </Section>
    );
    

}

export default AdminCourses;
