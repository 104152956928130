import React, { useContext, useEffect, useRef, useState } from 'react';
import { CourseContext } from './contexts/CourseContext';
import { Level, List, Section, Button, Dropdown, Heading } from 'react-bulma-components';
import { AiOutlineDown, AiOutlineUp } from 'react-icons/ai';

const AdminNewUser = props => {
    const [email, setEmail] = useState('');
    const [selected, setSelected] = useState('');
    const [showCourses, setShowCourses] = useState(false);
    const [courses, delCourse, getCourse, setCourses] = useContext(CourseContext);
    const [showUserCreateModal, setShowUserCreateModal] = useState(false);
    const [validatedEmail, setValidatedEmail] = useState(false);
    const [validatedRole, setValidatedRole] = useState(false);
    const [createMultipleUsers, setCreateMultipleUsers] = useState(false);
    const [multipleEmails, setMultipleEmails] = useState('');
    const emailRef = useRef();
    const roleRef = useRef();


    useEffect(()=>{
        if(email.length > 0  && email.indexOf('@') !== -1 && email.indexOf('.') !== -1) {
            setValidatedEmail(true);
        } else {
            setValidatedEmail(false);
        }
    },[email])

    useEffect(()=>{
        if( selected !== '') {
            setValidatedRole(true);
        } else {
            setValidatedRole(false);
        }
    },[selected])


    const handleInvite = () => {
        if(!validatedEmail || !validatedRole) return;
        setShowUserCreateModal(true);
        
        fetch("https://q9dyambaka.execute-api.eu-west-1.amazonaws.com/prod/users/", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email:email.toLowerCase(),
                group:selected
            })
        }).then(res => res.json())
        .then(res => {
            console.log(selected);
            setShowUserCreateModal(false);
            props.update[1](!props.update[0]);
            props.setNewUser(false);
        });
    }

    const handleMultipleInvites = async () => {
        if(multipleEmails.length > 0) {
            setShowUserCreateModal(true);
            let newUsers = multipleEmails.split('\n');
        
            for(let i = 0; i < newUsers.length; i++) {
                await fetch("https://q9dyambaka.execute-api.eu-west-1.amazonaws.com/prod/users/", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        email:newUsers[i].trim().toLowerCase(),
                        group:selected
                    })
                }).then(res => res.json())
                .then(res => {    
                }).catch((e) => console.log(e));
            }
            props.update[1](!props.update[0]);
            props.setNewUser(false);
            setShowUserCreateModal(false);
        } else {
            alert('FEIL: Mangler eposter!');
        }
    }

    return (
        <Section>
            <Heading size={4}>Opprett bruker</Heading>
                <Level>
                    <Level.Side align="left">
                        <Level.Item>
                            Opprette flere brukere samtidig?
                        </Level.Item>
                        <Level.Item>
                            <input type="checkbox"  checked={createMultipleUsers} onChange={() => setCreateMultipleUsers(!createMultipleUsers)} />
                        </Level.Item>
                    </Level.Side>
                </Level>
            {
                !createMultipleUsers ?
                    <div className='field'>
                        <div className='label' >Epost</div>
                        <div className='control'>
                            <input ref={emailRef} className={validatedEmail ? 'input is-success' : 'input is-danger'} type='text'  onChange={(e) => { setEmail(e.target.value) }} />
                        </div>
                    </div>
                :
                <div className='field'>
                    <div className='label' >Eposter (1 per linje)</div>
                    <div className='control'>
                        <textarea type='textarea'  className='textarea' onChange={(e) => { setMultipleEmails(e.target.value) }} />
                    </div>
                </div>
            }
            <div className='field'>
                <div className='label' >Rolle</div>
                <Dropdown
                    value={selected}
                    onChange={(selected) => setSelected(selected)}
                    ref={roleRef}
                    color={validatedRole ? 'success' : 'danger'}
                >
                    <Dropdown.Item
                        value=''
                    >
                        { ' - ' }  
                    </Dropdown.Item>
                    <Dropdown.Item
                        value='teacher'
                    >
                        Lærer
                    </Dropdown.Item>
                    <Dropdown.Item
                        value='student'
                    >
                        Elev
                    </Dropdown.Item>
                    <Dropdown.Item
                        value='admin'
                    >
                        Admin
                    </Dropdown.Item>
                </Dropdown>
            </div>
            {
            /*
            <div className='field'>
                <div className='label' >Kurstilgang</div>
                <List>
                    <List.Item>
                        <Level>
                            <Level.Side align='left'>
                                <Level.Item>
                                    <label className="checkbox" disabled={ showCourses ? true : false} >
                                        <input className="checkbox" type="checkbox" disabled={ showCourses ? true : false} checked={showCourses ? false : null} style={{ marginRight: '0.5em' }} />
                                        Alle
                                    </label>
                                </Level.Item>
                            </Level.Side>
                            <Level.Side align='right'>
                                {
                                    !showCourses ? 
                                        <Level.Item
                                            onClick={() => setShowCourses(true)}
                                        >
                                            <AiOutlineDown className='showMore' />
                                        </Level.Item>
                                    :
                                        <Level.Item
                                            onClick={() => setShowCourses(false)}
                                        >
                                            <AiOutlineUp className='showMore' />
                                        </Level.Item>
                                }
                            </Level.Side>
                        </Level>
                    </List.Item>
                    {
                        showCourses && courses.length !== 0 ?
                            courses.map((course, key) => {
                                return(
                                    <List.Item key={key}>
                                        <Level>
                                            <Level.Side align='left'>
                                                <Level.Item>
                                                    <label className="checkbox">
                                                        <input
                                                            className="checkbox" 
                                                            type="checkbox" 
                                                            checked={course.selected} 
                                                            onChange={() => {
                                                                course.checked=!course.checked;
                                                                let temp = [...courses];
                                                                setCourses([]);
                                                                setCourses(temp);
                                                            }} 
                                                            style={{ marginRight: '0.5em' }} />
                                                        {course.name}
                                                    </label>
                                                </Level.Item>
                                            </Level.Side>
                                        </Level>
                                    </List.Item>
                                );
                            })
                        : null
                    }
                </List>
            </div>
            */
            }
            {
                !createMultipleUsers ? 
                <Button 
                    color='info'
                    onClick={() => handleInvite()}
                    loading={showUserCreateModal}
                    disabled={validatedEmail && validatedRole ? false : true}
                >
                    Inviter
                </Button>
                :
                <Button 
                    color='info'
                    onClick={() => handleMultipleInvites()}
                    loading={showUserCreateModal}
                    disabled={validatedRole ? false : true}
                >
                    Inviter
                </Button>
            }
            
            <Button 
                color='danger'
                onClick={() => props.setNewUser(false)}
                style={{marginLeft: '1em'}}
            >
                Avbryt
            </Button>
        </Section>
    );

}

export default AdminNewUser;
