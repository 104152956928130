import React, {useState, createContext, useEffect} from 'react';
import {Auth} from 'aws-amplify';

export const StatusContext = createContext();

export const StatusContextProvider = props => {
    
    const [loggedIn, setLoggedIn] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [updateHeader, setUpdateHeader] = useState(false);

    useEffect(() => {
        console.log('update header in context');
        Auth.currentAuthenticatedUser()
        .then((user) => {
            setLoggedIn(true);
            if(user.signInUserSession.accessToken.payload['cognito:groups'].indexOf('admin') !== -1 ) {
                setIsAdmin(true);
            }
        })
        .catch((e) => {
            console.log(e);
            setLoggedIn(false);
            setIsAdmin(false);
        });
    },[,updateHeader])

    return (
        <StatusContext.Provider value={[loggedIn, setLoggedIn, isAdmin, setIsAdmin, updateHeader, setUpdateHeader]}>
            {props.children}
        </StatusContext.Provider>
    );
}